.login-card {
  /* max-width: 496px;
  min-height: 463px; */
  margin: 1px auto;
  padding: 0 30px;
  box-sizing: border-box;
  border-radius: 8px;
}

.auth-form {
  width: 30%;
  max-width: 450px;
  margin: 5rem auto;
  border-radius: 8px;
  /* border: 1px solid #dadce0; */
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  position: relative;
}
.auth-form .dots.top-right {
  position: absolute;
  /* right: 0; */
  right: -3rem;
  top: -3rem;
  z-index: -1;
}
.auth-form .dots.bottom-left {
  position: absolute;
  bottom: -4rem;
  left: -4rem;
  z-index: -1;
}

.auth-form .login-container {
  z-index: 999;
}
@media screen and (max-width: 480px) {
  .auth-form {
    width: 95%;
    margin: 10rem auto 0;
  }
}

@media screen and (min-width: 481px) and (max-width: 1000px) {
  .auth-form {
    width: 60%;
    margin: 5rem auto 0;
  }
}

.auth-form .two-fa-input {
  padding: 12px 10px;
}

.auth-form .primary-button {
  max-width: none;
}

.auth-form .app-name {
  padding: 1rem 0 2rem !important;
}

.auth-form .logo-size.default {
  margin-top: -2rem;
}

.auth-form .app-name.default {
  margin-top: -3rem;
}

.forget-password-container {
  margin-top: 24px;
  text-align: center;
}

.forget-password-note {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: var(--primary-text-color);
}

.forget-password-link {
  margin-left: 0.2em;
  cursor: pointer;
  color: var(--primary-custom-color);
}

.log-icon-top-container {
  max-width: 496px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  top: 1em;
  margin: 0 auto;
  /*background: var(--primary-custom-color);*/
}

.login-text-container {
  text-align: left;
  margin-top: 71px;
}

.login-form-container {
  margin-top: 28px;
}

.login-greeting {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: var(--primary-text-color);
}

.login-note {
  margin-top: -0.5em;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  color: var(--primary-text-color);
}

.login-container {
  position: relative;
  padding: 1rem 0 3.5rem;
}

.login-alert-container {
  position: relative;
  top: 1em;
}

.custom-form-card {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(13, 79, 100, 0.12);
  border-radius: 5px;
}

.validation-error {
  border: 1px solid var(--error-color) !important;
}

.app-name.top {
  margin-top: -1.5rem;
  margin-bottom: 0;
}

@media screen and (max-width: 600px) {
  .login-card {
    padding: 0 25px;
  }
}

@media screen and (max-width: 400px) {
  .login-card {
    padding: 0 20px;
  }
}
