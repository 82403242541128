.p-dialog-content {
  max-height: 90vh !important;
  overflow-y: scroll;
}

.modal-title {
  margin-top: 0;
  font-weight: bold;
}

.modal-subtitle {
  font-size: 0.8rem;
}

.hr {
  width: 100%;
  height: 0.025rem;
  background: #6d8c98;
  margin: 0.5rem 0 1.5rem;
}
