.p-tabview-selected {
  background-color: var(--primary-text-color);
  border-top-right-radius: 8px !important;
  margin-bottom: 0.5em;
}

.p-tabview-ink-bar {
  background-color: var(--primary-text-color) !important;
}

.p-tabview-nav-link {
  color: var(--primary-text-color) !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}
.p-tabview-nav-link:focus {
  color: #ffffff !important;
  border-top-right-radius: 8px !important;
}

.details-value {
  font-style: normal;
  font-weight: 600;
  position: relative;
  top: 0.3em;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0115367em;
  /* Text Blue */
  color: var(--primary-text-color);
}

.details-label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  position: relative;
  top: 0.3em;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0115367em;
  /* Header Text */
  color: #5d7f8d;
}
