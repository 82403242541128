.App {
  text-align: center;
}
body {
  background: var(--body-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.text-light-grey {
  color: #5d7f8d;
}

.bg-white {
  background-color: #ffffff;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
}

.bulk-upload .bare-button:hover {
  text-decoration: underline;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.break-word {
  word-wrap: break-word;
}

.mobile-table {
  margin-top: 2rem !important;
}

.bare-button {
  background: none;
  border: none;
  outline: none;
  font-weight: bold;
  color: #0371ac;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem 0 !important;
}

.img-404 {
  width: 25rem;
  height: auto;
}

.underline {
  text-decoration: underline !important;
}

.flex.default {
  display: flex;
  align-items: center;
}

.requery-management .dcir-row {
  margin-left: 0;
  padding-left: 0;
}

.requery-management .p-dialog,
.workflow-requests .p-dialog {
  min-width: 560px !important;
}

.requery-management .filter .p-dialog,
.workflow-requests .filter .p-dialog {
  width: 500px !important;
}

.details-value {
  word-break: break-word;
}

.dcir-row.no-underline {
  border-bottom: none !important;
}

.workflow-requests .sub-heading {
  font-weight: bold;
  font-size: 0.9rem;
  color: #6d8698;
}

.flex.default.end {
  justify-content: flex-end;
}

.p-accordion
  .p-accordion-tab:not(.p-accordion-tab-active)
  .p-accordion-toggle-icon {
  color: rgb(109, 140, 152);
  transform: translateY(-4.5px) rotate(-270deg);
}

.p-accordion .p-accordion-tab.p-accordion-tab-active .p-accordion-toggle-icon {
  color: rgb(109, 140, 152);
  transform: translateY(-4.5px) rotate(-180deg);
}

.text-small {
  font-size: 0.8rem;
}

.p-toast-top-right {
  top: 5rem !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
