.details-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */
  color: var(--primary-text-color);
}

.details-container {
  /* width: 500px; */
  position: relative;
  text-align: left;
}

.details-container .dcir-row {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

@media screen and (max-width: 765px) {
  .details-container {
    max-width: 700px;
    width: 100%;
  }
}

.details-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin-top: -1.5em;
  color: var(--primary-text-color);
}

.details-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  position: relative;
  top: 0.3em;
  line-height: 14px;
  /* Text Blue */
  color: #5d7f8d;
}
.details-value {
  font-style: normal;
  font-weight: 500;
  position: relative;
  top: 0.3em;
  font-size: 12px;
  line-height: 14px;
  /* Text Blue */
  color: var(--primary-text-color);
}

.dcir-row:after {
  content: "";
  display: table;
  clear: both;
}
.dcir-row {
  border-bottom: 1px solid #e0e8f4 !important;
}
@media screen and (max-width: 500px) {
  .action-text p {
    font-size: 0.8rem;
  }
}
