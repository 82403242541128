.header {
  /* height: 60px; */
  top: 0;
  /* width: 100%; */
  z-index: 50;
  background: #ffffff !important;
}

.header a {
  font-size: 0.8rem;
  color: #0b3275;
}

.header-container {
  margin: 0 auto;
  max-width: 1400px;
}

.header .header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#sub-header-id {
  display: block;
}
#main-header-id {
  display: none;
}

.header-icon {
  margin: 1em;
  width: 28px;
  height: 17px;
}

.log-out {
  margin: 0 1.5em;
  width: 20px;
  height: auto;
}

.pi-book::before {
  fill: "#0371ac";
}
