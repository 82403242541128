.sider {
  background: #ffffff;
}

.logo {
  width: 100px;
  height: 100px;
  margin: 1em auto;
}

.accordion-demo .accordion-custom i,
.accordion-demo .accordion-custom span {
  vertical-align: middle;
}

.accordion-demo .accordion-custom span {
  margin: 0 0.5rem;
}

.accordion-demo .p-accordion p {
  line-height: 1.5;
  margin: 0;
}

.custom-tab-icon {
  position: relative;
  top: 0.1em;
}

.p-accordion-header,
.p-accordion-content,
.p-accordion .p-accordion-tab {
  border: 0 !important;
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
  font-weight: 800;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.0126316em;
}

.custom-tab-content {
  margin: 0;
  min-height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  font-size: 13px;
  padding: 6px 40px 6px 40px;
  user-select: none;
  cursor: pointer;
  width: 100%;
  color: rgb(109, 140, 152);
}

.p-accordion-header-link {
  color: var(--primary-text-color);
}

.p-accordion,
.p-accordion-tab,
.p-accordion-header-link:hover {
  color: var(--primary-custom-color) !important;
}

.p-accordion-header-text:hover {
  color: var(--primary-custom-color) !important;
}

.p-accordion .p-accordion-tab.p-accordion-tab-active .p-accordion-header-link {
  color: var(--primary-custom-color) !important;
}

.custom-tab-active {
  background: var(--body-color-dark) !important;
  color: var(--primary-custom-color) !important;
}

.custom-accordion-header:hover {
  color: var(--primary-custom-color) !important;
}

.custom-tab-text {
  position: relative;
  top: -0.25em;
  left: 0.8em;
  font-weight: 800;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.0126316em;
  white-space: nowrap;
}
.custom-tab-title {
  font-weight: 800;
  font-size: 13px;
  line-height: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.nav-profile-title,
.nav-profile-username,
.nav-profile-subtitle{
  font-style: normal;
  font-weight: normal;
  font-size: 12.9943px;
}

.nav-profile-title {
  line-height: 15px;
  color: var(--primary-text-color);
}
.nav-profile-username{
  line-height:15px;
  color: var(--primary-text-color);
}
.nav-profile-subtitle {
  line-height: 19px;
  color: #bfc5d2;
}

.nav-profile-text-container {
  position: relative;
  left: -1.25em;
}

.nav-profile-container {
  margin-left: 35px;
  margin-top: -2em;
  margin-bottom: 18px;
}

.singleNavContainer {
  width: 250px;
  padding: 15px 24px;
  cursor: pointer;
  height: 51px;
}
.singleNavContainer-active {
  background: var(--body-color);
}
.singleNavContainer-text {
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.0126316em;
}
.adjust-single-nav {
  position: relative;
}
.singleNavContainer-icon {
  width: 21px;
  height: 23px;
  display: flex;
  align-items: center;
}

.p-accordion-toggle-icon.pi {
  font-size: 0.7rem;
}

.p-accordion .p-accordion-content {
  padding-top: 0;
  padding-bottom: 0;
}

.d-flex {
  display: flex;
}

.custom-tab-text svg {
  width: 1.3rem;
  margin-bottom: -1.2rem;
}

.align-center {
  align-items: center;
}

.nav-profile-title,
.nav-profile-subtitle {
  text-transform: capitalize;
}

.nav-profile-title.version {
  color: #0371a0;
}

.p-accordion .p-accordion-tab.p-accordion-tab-active {
  margin-bottom: 0;
}

.p-accordion-header-link .p-accordion-toggle-icon {
  position: absolute;
  right: 1rem;
  top: 1.75rem;
  /* margin-bottom: 0; */
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  /* padding: 1rem 0 0 0; */
  background: none;
  border: none;
  /* margin-bottom: 1rem; */
}

.sidebar-accordion-header {
  display: flex;
  align-items: center;
  padding: 1rem 0 0.5rem 1rem;
}

.sidebar-accordion-header svg {
  width: 21px;
  height: 23px;
}

@media screen and (max-width: 800px) {
  .sidenav {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
