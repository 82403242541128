button {
  padding: 0.5rem 1.5rem !important;
  width: auto;
}

button.primary {
  color: #ffffff;
  width: auto;
  cursor: pointer;
  background: var(--primary-custom-color);
  border: 0 none;
  padding: 0.571rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}
button.disabled {
  color: #ffffff;
  width: auto;
  cursor: pointer;
  background: var(--primary-custom-color-disabled);
  border: 0 none;
  padding: 0.571rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}

button.secondary {
  width: auto;
  opacity: 0.8;
  cursor: pointer;
  font-style: normal;
  min-height: 38px;
  outline: none !important;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  /*box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);*/
  line-height: 1rem;
  /* identical to box height */
  letter-spacing: 0.000909091em;
  /* #EB5757 */
  background: rgba(235, 87, 87, 0.3);
  border: 1px solid rgba(235, 87, 87, 0.2);
  border-radius: 4px;
  color: #eb5757;
  font-weight: 600;
  font-size: 0.875rem;
}
button.tetiary {
  width: auto;
  opacity: 0.8;
  cursor: pointer;
  font-style: normal;
  min-height: 38px;
  outline: none !important;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  /*box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);*/
  line-height: 1rem;
  /* identical to box height */
  letter-spacing: 0.000909091em;
  /* #EB5757 */
  background: #277db2;
  border: 1px solid rgba(235, 87, 87, 0.2);
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 0.875rem;
}
button.tetiary:hover {
  background: var(--primary-custom-color);
}

button.bare {
  background: none;
  outline: none;
  border: none;
  color: #0371ac;
}
