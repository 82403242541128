.transaction-details-accordion
  .p-accordion
  .p-accordion-header
  .p-accordion-header-link {
  padding: 1rem;
  background: none;
  border: none;
}

.transaction-details-accordion
  .p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
