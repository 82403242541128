.actions div:first-of-type button,
.actions button:first-of-type {
  margin-right: 1rem;
}
/* 
.actions div:first-of-type button,
.actions button:nth-child(1){
  margin-right: 1rem;
} */

.actions button {
  width: 100%;
}
