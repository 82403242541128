p.error {
  font-size: 0.8rem;
  color: red;
  margin-top: 0.25rem;
}
input.error,
.p-dropdown.error {
  border: 1px solid red !important;
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-loading-container {
  position: absolute;
  right: 0.75rem;
  top: 0.8rem;
}

.flag {
  padding: 0;
  margin: 0;
  height: 4rem;

  width: 2rem;
  overflow: hidden;
  border-radius: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
}
